import React from "react"
import AOS from "aos"
import "aos/dist/aos.css"

import "./SectionOne.scss"
// import ambiente from "../../../../../images/mobile/solutions/fabtech-ilustration.svg"
import ambiente from "../../../../../images/illustrations/fabtech-2.svg"
import seta from "../../../../../images/mobile/solutions/arrow-desk.svg"
const SectionOne = () => {
  React.useEffect(() => {
    AOS.init({
      once: true,
      duration: 2000,
      easing: "ease-in-out",
      offset: 50,
    })
  })
  return (
    <div id="software-section-one">
      <div id="content">
        <div className="section-one-text">
          <h1 data-aos="fade-right" data-aos-delay="100" className="title">
            FAB tech
          </h1>
          <h2 data-aos="fade-right" data-aos-delay="150" className="subtitle">
            Desenvolvimento de produtos digitais
          </h2>
          <p data-aos="fade-right" data-aos-delay="500">
            Idealizamos e co-criamos produtos digitais, validando ideias e
            desenvolvendo softwares customizados e exclusivos.
          </p>
        </div>
        <div className="section-one-image">
          <img
            className="illustration"
            data-aos="fade-in"
            src={ambiente}
            alt="Duas mãos: Uma indo em direção a um dispositivo móvel, outra indo em direção a um na tela de um computador. Com uma pessoa flutuando ao redor das tecnologias. Indicando desenvolvimento de software e de aplicativos da FABTECH."
          />
          <div className="section-one-member">
            <img src={seta} alt="arrow" className="arrow bounce-infinite" />
          </div>
        </div>
      </div>
      <div className="section-one-button">
        <form className="form-inline">
          <a href="/contact/#form" style={{ textDecoration: "none" }}>
            <button className="btn" type="button">
              Seja um cliente
            </button>
          </a>
        </form>
      </div>
    </div>
  )
}

export default SectionOne
