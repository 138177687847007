import React, { useState, useEffect } from "react"
import { FiPlus, FiMinus } from "react-icons/fi"
import AOS from "aos"
import "aos/dist/aos.css"

import ImpactCases from "../../../../ImpactCases/ImpactCases"
import PinnedMenu from "../../../../../utils/PinnedMenu"

import vitrium from "../../../../../images/enterprises-colorized/vitrium.svg"
import digna from "../../../../../images/enterprises-colorized/digna.svg"
import neoron from "../../../../../images/enterprises-colorized/neoron.svg"

import "./SectionTwo.scss"
import "../../../../../utils/PinnedMenu/styles.scss"

const SectionTwo = () => {
  const [expandedDesignSprint, setExpandedDesignSprint] = useState(false)
  const [expandedDigitalProduct, setExpandedDigitalProduct] = useState(false)
  const [active, setActive] = useState("design-sprint")

  const [fixedDesignSprint, setFixedDesignSprint] = useState(false)
  const [fixedDigitalProduct, setFixedDigitalProduct] = useState(false)

  useEffect(() => {
    AOS.init({
      once: true,
      duration: 2000,
      easing: "ease-in-out",
      offset: 50,
    })
  })

  useEffect(() => {
    function fixedMenusInTop() {
      const designSprintMenu = new PinnedMenu(
        "design-sprint-menu",
        "design-sprint-content"
      )
      const digitalProductMenu = new PinnedMenu(
        "digital-product-menu",
        "digital-product-content"
      )
      const menuDesktop = new PinnedMenu("collapse-and-slider-menu", "sliders")

      setFixedDesignSprint(
        designSprintMenu.shouldItBePinned(expandedDesignSprint)
      )
      if (designSprintMenu.shouldItBePinned(expandedDesignSprint))
        designSprintMenu.pin()
      else designSprintMenu.unpin()

      setFixedDigitalProduct(
        digitalProductMenu.shouldItBePinned(expandedDigitalProduct)
      )
      if (digitalProductMenu.shouldItBePinned(expandedDigitalProduct))
        digitalProductMenu.pin()
      else digitalProductMenu.unpin()

      if (menuDesktop.shouldItBePinned(true, true)) menuDesktop.pin(true)
      else menuDesktop.unpin(true)
    }
    window.addEventListener("scroll", fixedMenusInTop)
    fixedMenusInTop()
  }, [expandedDesignSprint, expandedDigitalProduct])

  return (
    <div id="software-section-two">
      <div id="content" />
      <div id="phases-tech"></div>
      <ul id="collapse-and-slider-menu" className="collapse-menu">
        <li className="collapse-menu-item">
          <button
            id="design-sprint-menu"
            className={`collapse-menu-header  ${
              active === "design-sprint" ? "active" : ""
            } ${fixedDesignSprint ? "fixed-mobile" : ""}`}
            onClick={() => {
              setExpandedDesignSprint(!expandedDesignSprint)
              setActive("design-sprint")
            }}
          >
            <h2 className="collapse-menu-title" id="design-sprint">
              DESIGN SPRINT
            </h2>
            {!expandedDesignSprint ? (
              <FiPlus size={30} />
            ) : (
              <FiMinus size={30} />
            )}
          </button>
          <div
            id="design-sprint-content"
            className={`collapse-menu-content 
                  ${expandedDesignSprint ? "expanded" : "contracted"}`}
          >
            <h3>Co-criação e concepção da solução</h3>
            <p>
              Construímos com você, de forma colaborativa, a melhor solução para
              o seu problema, validando desde o fluxo do usuário até a
              interface.
              <br />
              <br />
              Utilizamos a abordagem da Design Sprint do Google, para criação de
              novas soluções e aprimoramento de processos. Otimizamos o seu
              tempo e o seu dinheiro antes de ir para a etapa de
              desenvolvimento.
            </p>
            <ImpactCases
              cases={[
                {
                  image: digna,
                  enterprise: "Digna",
                  title: "Portal Digna",
                  text:
                    "A Digna precisava melhorar a sua comunicação e seu relacionamento com o cliente. Por meio de um Design Sprint 100% remoto, com uma equipe multidisciplinar, desenhamos o Portal Digna, para acesso de clientes e parceiros. O protótipo foi testado com usuários finais que avaliaram o produto com base em seus níveis de usabilidade, adoção efetiva, design e navegabilidade.",
                },
                {
                  image: vitrium,
                  enterprise: "Vitrium",
                  title: "Design Sprint Vitrium",
                  text:
                    "A Vitrium chegou na FABWORK com um problema: a comunicação lenta entre vidraceiros e a fábrica. Por meio do Design Sprint, idealizamos juntos uma aplicação mobile para a interação com o vidraceiro e um sistema desktop para controle da fábrica. No decorrer dos 5 dias a aplicação foi desenhada e testada com o usuário final, chegando validada para o time de desenvolvimento.",
                },
              ]}
            />
            <div className="know-more-mobile">
              <p>Quer saber mais sobre o Design Sprint?</p>
              <a href="/contact/#">
                <button>Fale Conosco</button>
              </a>
            </div>
          </div>
        </li>
        <li className="collapse-menu-item">
          <button
            id="digital-product-menu"
            className={`collapse-menu-header  ${
              active === "digital-product" ? "active" : ""
            } ${fixedDigitalProduct ? "fixed-mobile" : ""}`}
            onClick={() => {
              setExpandedDigitalProduct(!expandedDigitalProduct)
              setActive("digital-product")
            }}
          >
            <h2 className="collapse-menu-title" id="digital-product">
              DESENVOLVIMENTO DE SOFTWARE
            </h2>

            {!expandedDigitalProduct ? (
              <FiPlus size={30} />
            ) : (
              <FiMinus size={30} />
            )}
          </button>
          <div
            id="digital-product-content"
            className={`collapse-menu-content 
                  ${expandedDigitalProduct ? "expanded" : "contracted"}`}
          >
            <h3>Desenvolvimento do software </h3>
            <p>
              Com uma ideia consolidada e com a viabilidade tecnológica
              confirmada, desenvolvemos uma solução, que pode ser um produto ou
              a automação de um processo dentro da sua empresa.
            </p>
            <p>
              Toda a jornada do produto é customizável e com tecnologia de ponta
              aplicada durante as etapas de desenvolvimento e execução.
            </p>
            <ImpactCases
              cases={[
                {
                  image: vitrium,
                  enterprise: "Vitrium vidros",
                  title: "Aplicativo VITEC",
                  text:
                    "Após o Design Sprint, a Vitrium visualizou que o investimento na automação de um processo traria economia de tempo para seus colaboradores e aceleraria os pedidos de seus fornecedores. A FABWORK desenvolveu um aplicativo - focado no vidraceiro - além de um sistema desktop para análise de dados e acompanhamento de pedidos - focado na fábrica.",
                },

                {
                  image: neoron,
                  enterprise: "Neoron",
                  title: "Plataforma Neoron",
                  text:
                    "A NEORON nasceu com o objetivo de melhorar o atendimento ao cliente e obter dados estratégicos, por meio de uma plataforma simples e intuitiva que atua com chatbots. Desenvolvemos a plataforma utilizando inteligência artificial conversacional e machine learning - com uma interface já validado em um Design Sprint.",
                },
              ]}
            />
            <div className="know-more-mobile">
              <p>Quer saber mais sobre o Desenvolvimento de Software?</p>
              <a href="/contact/#">
                <button>Fale Conosco</button>
              </a>
            </div>
          </div>
        </li>
      </ul>
      <ul
        id="sliders"
        className="slider-menu"
        data-aos="fade-up"
        data-aos-delay="250"
      >
        <li
          id="design-sprint-lg"
          className={`slider-menu-page ${
            active === "design-sprint" ? "active" : ""
          }`}
        >
          <div className="content-container">
            <h1 className="title">Co-criação e concepção da solução</h1>
            <p className="text">
              Construímos com você, de forma colaborativa, a melhor solução para
              o seu problema, validando desde o fluxo do usuário até a
              interface.
              <br />
              <br />
              Utilizamos a abordagem da Design Sprint do Google, para criação de
              novas soluções e aprimoramento de processos. Otimizamos o seu
              tempo e o seu dinheiro antes de ir para a etapa de
              desenvolvimento.
            </p>
            <div className="know-more">
              <p>Quer saber mais sobre o Design Sprint?</p>
              <a href="/contact/#">
                <button>Fale Conosco</button>
              </a>
            </div>
          </div>

          <div className="carousel-container">
            <ImpactCases
              cases={[
                {
                  image: vitrium,
                  enterprise: "Vitrium",
                  title: "Design Sprint Vitrium",
                  text:
                    "A Vitrium chegou na FABWORK com um problema: a comunicação lenta entre vidraceiros e a fábrica. Por meio do Design Sprint, idealizamos juntos uma aplicação mobile para a interação com o vidraceiro e um sistema desktop para controle da fábrica. No decorrer dos 5 dias a aplicação foi desenhada e testada com o usuário final, chegando validada para o time de desenvolvimento.",
                },
                {
                  image: digna,
                  enterprise: "Digna",
                  title: "Portal Digna",
                  text:
                    "A Digna precisava melhorar a sua comunicação e seu relacionamento com o cliente. Por meio de um Design Sprint 100% remoto, com uma equipe multidisciplinar, desenhamos o Portal Digna, para acesso de clientes e parceiros. O protótipo foi testado com usuários finais que avaliaram o produto com base em seus níveis de usabilidade, adoção efetiva, design e navegabilidade.",
                },
              ]}
            />
          </div>
        </li>
        <li
          id="digital-product-lg"
          className={`slider-menu-page ${
            active === "digital-product" ? "active" : ""
          }`}
        >
          <div className="content-container">
            <h1 className="title">Desenvolvimento de aplicativos e sistemas</h1>
            <p className="text">
              Com uma ideia consolidada e com a viabilidade tecnológica
              confirmada, desenvolvemos uma solução, que pode ser um produto ou
              a automação de um processo dentro da sua empresa.
              <br />
              <br />
              <strong>
                Toda a jornada do produto é customizável e com tecnologia de
                ponta aplicada durante as etapas de desenvolvimento e execução
              </strong>
            </p>
            <div className="know-more">
              <p>Quer saber mais sobre o Desenvolvimento de Software?</p>
              <a href="/contact/#">
                <button>Fale Conosco</button>
              </a>
            </div>
          </div>

          <div className="carousel-container">
            <ImpactCases
              cases={[
                {
                  image: vitrium,
                  enterprise: "Vitrium vidros",
                  title: "Aplicativo VITEC",
                  text:
                    "Após o Design Sprint, a Vitrium visualizou que o investimento na automação de um processo traria economia de tempo para seus colaboradores e aceleraria os pedidos de seus fornecedores. A FABWORK desenvolveu um aplicativo - focado no vidraceiro - além de um sistema desktop para análise de dados e acompanhamento de pedidos - focado na fábrica.",
                },
                {
                  image: neoron,
                  enterprise: "Neoron",
                  title: "Plataforma Neoron",
                  text:
                    "A NEORON nasceu com o objetivo de melhorar o atendimento ao cliente e obter dados estratégicos, por meio de uma plataforma simples e intuitiva que atua com chatbots. Desenvolvemos a plataforma utilizando inteligência artificial conversacional e machine learning - com uma interface já validado em um Design Sprint.",
                },
              ]}
            />
          </div>
        </li>
      </ul>
    </div>
  )
}

export default SectionTwo
